var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("v-col", { attrs: { cols: "8" } }, [
            _c("div", { staticClass: "align-self-center" }, [
              _c(
                "h2",
                {
                  staticClass: "font-weight-light",
                  staticStyle: { "font-size": "16px", "padding-bottom": "2vh" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("authentication.chooseTeam.heading")) +
                      " "
                  )
                ]
              ),
              _c(
                "div",
                [
                  _c(
                    "v-radio-group",
                    {
                      model: {
                        value: _vm.teamId,
                        callback: function($$v) {
                          _vm.teamId = $$v
                        },
                        expression: "teamId"
                      }
                    },
                    _vm._l(_vm.teamsArray, function(t) {
                      return _c("v-radio", {
                        key: t.userMoveRequestedBy,
                        attrs: {
                          label: t.userMoveRequestedBy.value,
                          value: t.teamId
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-space-around",
                      staticStyle: { "padding-top": "5vh" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.acceptInvitation()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("authentication.chooseTeam.accept"))
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.reject()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("authentication.chooseTeam.reject"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }