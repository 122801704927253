<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="8">
        <div class="align-self-center">
          <h2
            class="font-weight-light"
            style="font-size: 16px; padding-bottom: 2vh"
          >
            {{ $t("authentication.chooseTeam.heading") }}
          </h2>
          <div>
            <v-radio-group v-model="teamId">
              <v-radio
                v-for="t in teamsArray"
                :key="t.userMoveRequestedBy"
                :label="t.userMoveRequestedBy.value"
                :value="t.teamId"
              ></v-radio>
            </v-radio-group>
            <div class="d-flex justify-space-around" style="padding-top: 5vh">
              <v-btn color="primary" outlined @click="acceptInvitation()">{{
                $t("authentication.chooseTeam.accept")
              }}</v-btn>
              <v-btn color="primary" @click="reject()">{{
                $t("authentication.chooseTeam.reject")
              }}</v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import {
  joinToTheTeam,
  rejectTeamInvitations,
  checkHasSubscriptionAndSetCustomerId,
  recordEndUserLogin,
  userTeamMemberInformation
} from "../../customApi";
import TeamService from "@/services/teamService.js";

export default {
  name: "ChooseTeam",
  props: {
    teams: Array
  },
  data() {
    return {
      teamsArray: [],
      teamId: null
    };
  },
  async mounted() {
    this.teamsArray = this.teams ?? [];
    if (this.teamsArray.length === 0) {
      await this.getTeamsToJoin();
    }
  },
  methods: {
    ...mapActions([
      "setCurrentUserData",
      "getUserTeamAndSubscriptionBaseInformation"
    ]),
    ...mapMutations([
      "addAsyncRequest",
      "removeAsyncRequest",
      "setTeamMemberId",
      "setTeamMemberInfo"
    ]),
    async getTeamsToJoin() {
      try {
        this.addAsyncRequest();
        let memberInfo = await TeamService.getTeamMemberInfo();
        this.setTeamMemberId(memberInfo.teamMemberId);
        this.teamsArray = memberInfo.teamsToJoinRequested;
        this.removeAsyncRequest();
      } catch (err) {
        this.removeAsyncRequest();
      }
    },
    async acceptInvitation() {
      confirm(this.$t("authentication.chooseTeam.confirmation.accept")) &&
        (await this.joinTeam());
    },
    async joinTeam() {
      try {
        this.addAsyncRequest();
        await joinToTheTeam(this.teamMemberId, this.teamId);
        await this.updateTeamInfo();
        await checkHasSubscriptionAndSetCustomerId();
        recordEndUserLogin();
        this.removeAsyncRequest();
        this.goToDashboard();
      } catch (err) {
        this.removeAsyncRequest();
      }
    },
    async reject() {
      confirm(this.$t("authentication.chooseTeam.confirmation.reject")) &&
        (await this.rejectInvitations());
    },
    async rejectInvitations() {
      try {
        this.addAsyncRequest();
        await rejectTeamInvitations(this.teamMemberId);
        await this.updateTeamInfo();
        if (this.teamMemberId) {
          await checkHasSubscriptionAndSetCustomerId();
        }
        this.removeAsyncRequest();
        this.goToDashboard();
      } catch (err) {
        this.removeAsyncRequest();
      }
    },
    goToDashboard() {
      var path = this.teamMemberId ? "/dashboard" : "/wellness";
      this.$router.push(path, () => {});
    },
    async updateTeamInfo() {
      let teamMemberInfo = await userTeamMemberInformation();
      await this.setCurrentUserData(teamMemberInfo);
      this.setTeamMemberId(teamMemberInfo.teamMemberId);
      this.setTeamMemberInfo(teamMemberInfo);
      if (this.teamMemberId) {
        await this.getUserTeamAndSubscriptionBaseInformation({
          memberId: this.teamMemberId,
          forceToUpdateExistedInformation: false
        });
      }
    }
  },
  computed: {
    ...mapGetters(["teamMemberId"])
  }
};
</script>
